import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()
console.log(serializedTokens,"serializedTokens")
const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.sparco,
  //   earningToken: serializedTokens.sparco,
  //   contractAddress: {
  //     97: '0xc864FBF36F7C32303111a2693841dFf0B222c3CF',
  //     56: '0x966b7089d14b729dCb0452aCBEcF20Ee593415D0',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.03805175038',
  //   sortOrder: 0,
  //   isFinished: false,
  // },
  {
    sousId: 1,
    stakingToken: serializedTokens.kings,
    earningToken: serializedTokens.kings,
    contractAddress: {
      97: '0xC81c8164D05dD46b754a8Fd11441593C0103Add7',
      56: '0x966b7089d14b729dCb0452aCBEcF20Ee593415D0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03805175038',
    sortOrder: 0,
    isFinished: false,
  }

]

export default pools
